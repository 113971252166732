<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <transition name="ecv-anchor-point-fade">
    <div v-if="showAnchor" class="ecv-anchor-point">
      <ul class="ecv-anchor-point__list">
        <li
          v-for="(anchor, index) in anchorList"
          :key="`${anchor.id}-${index}`"
          @click="goTo(anchor.id)"
          class="ecv-anchor-point__list__item pointer"
          :class="{ active: anchor.id === selectId }"
        >
          <div class="ecv-anchor-point__list__item__title">{{ anchor.name }}</div>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  data: () => ({
    selectId: "",
    duration: 300,
    timer: null,
  }),
  computed: {
    storeShowAnchor() {
      return this.$store.getters['base/showAnchor']
    },
    showAnchor() {
      if(!this.storeShowAnchor) return false
      return !_isEmpty(this.anchorList);
    },
    anchorList() {
      return this.$store.getters["base/anchor"];
    },
    navHeight() {
      return this.$store.getters["base/navHeight"];
    },
    anchorListPosition() {
      if (_isEmpty(this.anchorList)) return [];
      return this.anchorList.reduce((array, item) => {
        const { id } = item;
        const target = $(`#${id}`)[0];
        if (!target) return array;
        const targetTop = $(`#${id}`).offset().top;

        const res =
          targetTop - this.navHeight < 0 ? 0 : targetTop - this.navHeight;
        array.push({
          top: res,
          bottom: res + target.offsetHeight,
          id,
        });
        return array;
      }, []);
    },
  },
  watch: {
    selectId() {
      this.setHash(this.selectId);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.listenScroll);
  },
  created() {
    window.addEventListener("scroll", this.listenScroll);
  },
  methods: {
    listenScroll() {
      if (this.goToing) return;
      const scrollTop = $(window).scrollTop();
      for (let i = this.anchorListPosition.length - 1; i >= 0; i--) {
        const item = this.anchorListPosition[i];
        if (
          scrollTop >= Math.floor(item.top) &&
          scrollTop <= Math.floor(item.bottom)
        ) {
          this.selectId = item.id;
          return;
        }
      }
      this.selectId = "";
    },
    goTo(id) {
      this.selectId = id;
      const target = $(`#${id}`)[0];
      if (!target) return;
      this.$vuetify.goTo(target, {
        offset: this.navHeight,
        duration: this.duration,
      });
      this.goToing = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.goToing = false;
      }, this.duration + 200);
    },
    setHash(id) {
      const path = window.location.pathname + window.location.search;
      this.$router.push({ path, hash: "#" + id });
    },
  },
};
</script>